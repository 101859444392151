.feature-item {
  border-radius: $border-radius-xl;
  padding: .5rem 1rem;

  .feature-item-title {
    margin-bottom: 0;
  }

  .feature-item-text {
    color: theme-color('divein-base');
    display: none;
    opacity: .7;
    margin-bottom: 0;
  }

  &.active {
    // background-color: #f3f5f9;
    background-color: #fcfcfc;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .12);

    .feature-item-title,
    .feature-item-text {
      color: #031b4e;
      cursor: default;
    }
  }

  @include media-breakpoint-up(md) {
    .feature-item-title {
      margin-bottom: .25rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .feature-item-text {
      display: block
    }
  }
}

$__GOOGLE_TAG_MANAGER__: unquote("GTM-P6W24BX"); $__AZURE_APP_INSIGHTS_KEY__: unquote("45199851-5851-4359-b324-7f734abf8caa"); $__DIVEIN_URL__: unquote("https://divein.studio"); $__DIVEIN_VIEWER_URL__: unquote("https://viewer.divein.studio"); $__DIVEIN_DASHBOARD_URL__: unquote("https://dashboard.divein.studio"); $__DIVEIN_API_URL__: unquote("\"https://diveinapi.azurewebsites.net\""); $__DIVEIN_API_CODE__: unquote("\"UZ/HWWiJF6kcSx7udKRL/vrKLtzayuJhNVSSSRoPOvPJMQzolxN3Bw==\""); $__DIVEIN_CDN_URL__: unquote("https://diveinstorageprod.blob.core.windows.net"); $__DIVEIN_STATIC_CDN_URL__: unquote("https://diveinhome.z13.web.core.windows.net"); $__DIVEIN_CORE_URL__: unquote("\"https://diveinstorageprod.blob.core.windows.net/core\""); $__DIVEIN_CORE_WEB_URL__: unquote("\"https://diveinstorageprod.blob.core.windows.net/core/diveInWeb/diveInWeb.js?hash=43609723531987643\""); $__B2C_SIGNIN_SIGNUP_POLICY__: unquote("\"B2C_1_SignInSignUpDiveIn\""); $__B2C_RESET_PASSWORD_POLICY__: unquote("\"B2C_1_ResetPasswordDiveIn\"");
@charset 'UTF-8';

// Font
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@200;400;700;900&display=swap");

// ## Variables
@import "abstracts/variables";

// ## Bootstrap
@import "~bootstrap/scss/bootstrap";

// ## Abstracts
@import "abstracts/base";

// ## Components
@import "components/card-list";
@import "components/crossfade-images";
@import "components/feature-item";
@import "components/hero-background";
@import "components/hero-request-demo";
@import "components/section-header";
@import "components/testimonial-carousel";

// ## Layout
@import "layout/navbar";
@import "layout/footer";

// ## Pages
@import "pages/authenticaton";

// ## Vendors
@import "flickity/css/flickity";

@import "vendors/flickity-carousel";
@import "vendors/hamburger-squeeze";

// Color system
$primary: #0069ff;
$secondary: #f3f5f9;
$dark: #010e28;
$divein-base: #031b4e;
$title-primary: #08569B;
$light: #515459;
$divein-base-body: #f4f8ff;

$theme-colors: (
  'divein-base': $divein-base,
  'primary': $primary,
  'secondary': $secondary,
  'dark': $dark,
  'title': $title-primary,
  'gray-light': $light,
  'base-body': $divein-base-body
);

// Body
//
// Settings for the `<body>` element.

$body-color: rgba($divein-base, .7);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-base: 'Roboto',
sans-serif;
$headings-color: $divein-base;
$font-weight-bolder: 900;

$font-size-base: 1rem;

$blockquote-small-color: $body-color;
$blockquote-small-font-size: $font-size-base;
$blockquote-font-size: $font-size-base;

// Components
//
// Define common padding and border radius sizes and more.

$border-radius-xl: .625rem;

// Navbar

$navbar-light-color: $divein-base;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;

// Forms

$input-placeholder-color: $body-color;
$input-color: $divein-base;
$input-border-color: $secondary;
$input-focus-border-color: $primary;
$input-focus-box-shadow: none;
$input-padding-y: .625rem;
$input-font-size: .875rem;

// Hero
$hero-bg: #f3f5f9;

.card-list {
  .card {
    margin-left: auto;
    margin-right: auto;
    transition: transform .3s;
    width: 19.375rem;

    &:hover {
      transform: translateY(-5px) scale(1.03);
    }
  }
}

// https://jonsuh.com/hamburgers/
@import "../abstracts/variables";

.hamburger {
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  border: 0;
  background-color: transparent;

  &:focus {
    outline: 0;
  }
}

.hamburger--squeeze .hamburger-inner {
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;

  &:before {
    transition: top 75ms ease 0.12s, opacity 75ms ease;
  }

  &:after {
    transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}

.hamburger--squeeze[aria-expanded="true"] .hamburger-inner {
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg);

  &:before {
    top: 0;
    transition: top 75ms ease, opacity 75ms ease 0.12s;
    opacity: 0;
  }

  &:after {
    bottom: 0;
    transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    transform: rotate(-90deg);
  }
}

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 24px;
}

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -2px;

  &,
  &:after,
  &:before {
    position: absolute;
    width: 30px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: $body-color;
  }

  &:after,
  &:before {
    display: block;
    content: "";
  }

  &:before {
    top: -10px;
  }

  &:after {
    bottom: -10px;
  }
}

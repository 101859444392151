.section-header {
  text-align: center;

  .section-header-info {
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-up(md) {
    margin: 0 auto;
    width: 40rem;

  }
}

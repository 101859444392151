.navbar .navbar-brand {
  width: 70%;

  .nav-logo {
    // height: 100%;
    width: 60%;
  }

  @include media-breakpoint-up(sm) {
    width: initial;

    .nav-logo {
      width: 15.625rem;
    }
  }
}

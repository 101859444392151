.crossfade-images {
  height: 12.2rem;
  position: relative;

  .crossfade-images-child {
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity .3s, transform .3s;
    transform: scale(.9);
    width: 100%;
  }

  img {
    display: block;
    height: 100%;
    margin: 0 auto;
    object-fit: contain;
  }

  .crossfade-images-child.active {
    opacity: 1;
    transform: scale(1);
  }

  @include media-breakpoint-up(sm) {
    height: 16.625rem;
  }

  @include media-breakpoint-up(lg) {
    height: 334px;

    .figure {
      padding: 0 2rem;
    }
  }
}

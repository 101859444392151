.hero-request-demo {
  .hero-information {
    color: #1A1B4E;
    z-index: 100;
    padding: 0 15px !important;

    .main-copy {
      font-size: 2.5rem;
      white-space: normal;
      text-align: center;
    }

    .complementary-copy {
      font-size: 1rem;
      font-weight: 200;
      opacity: .8;
      text-align: center;

    }

    .sign-up-btn-hero {
      width: 90%;
      height: 50px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
  }

  .hero-form {
    width: 25rem;
  }

  @include media-breakpoint-up(sm) {

    //starts on 576px
    .hero-information {
      padding: 0 !important;

      .main-copy {
        font-size: 2.9rem;
        overflow: visible;
        white-space: nowrap;
      }

      .complementary-copy {
        font-size: 1.1rem;
        font-weight: 200;
        opacity: .8;
        text-align: center;
        white-space: nowrap;
      }

      .sign-up-btn-hero {
        width: 60%;
        margin: 0;
      }
    }
  }

  @include media-breakpoint-up(md) {

    //starts on 768px
    .hero-information {
      width: 25rem;

      .main-copy {
        font-size: 3.5rem;
        overflow: visible;
        white-space: nowrap;
      }
    }
  }

  @include media-breakpoint-up(lg) {

    //starts on 992px    
    .hero-information {
      width: 30rem !important;

      .complementary-copy {
        text-align: left;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    //starts on 1200px
    min-height: 34rem;
    max-height: 44rem;

    .hero-information {
      width: 32.5rem;
    }
  }
}

:root {
  --primary: #35dfb5;
  --danger-bg: #f8d7da;
  --danger-color: #721c24;
  --white: #fff;
  --input-border: #515459;
  --gray: #d8d8d8;
  --gray-900: #848484;
  --text-body: #666;
  --button-bg: #449EF7;
  --dark: #000c14;
  --hover-bg: #b3b8c4;
  --button-hover-bg: #dbedff;
  --bg-body: #F4F8FF;
}

.content-links {
  text-align: center;

  .selected-link-item {
    border-bottom: 2px solid var(--text-body);
    font-weight: bold;
    padding: 0.3rem 0.5rem;
  }

  .signup-tittle {
    color: #08569B;
    font-weight: 700;
    font-size: 37px;
    margin-bottom: 1rem;
  }

  .link-item {
    color: var(--text-body);
    opacity: 0.7;
    padding: 0.3rem 0.5rem;
    text-decoration: none;
    transition: opacity 0.2 ease-in-out;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.logo-img {
  margin: 3rem auto 2rem; 
}

.login-title {
  color: var(--primary);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.login-info-title {
  color: var(--white);
  font-size: 2rem;
  margin-bottom: 4rem;
  text-align: center;
}

.login-svg {
  display: block;
  margin: 0 auto;
  max-width: 30rem;
}

.authentication-main {
  opacity: 1 !important;
  background-color: var(--bg-body);

}

.authentication-main > div {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  &:last-child {
    display: none;
    width: 80%;
  }
}

.container-login{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 80vw;
  margin: 0 auto;
  box-sizing: border-box;
}

.form-content {
  margin: 0 auto;
  width: 100%;
  #divein-login{
    color: black;
    text-decoration: none;
  }
  #email-existing{
    color: black;
    text-decoration: none;
    display: none;
  }
  .existing-user-wrapper{
    margin-top: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .authentication-main {
    display: flex;

    > div {
      width: 50%;

      &:last-child {
        display: flex;
      }
    }
  }

  .logo-img {
    margin: 1rem 0 auto 2rem; 
    position: absolute;
    top: 0;
    left: 0;
  }

  .container-login{
    width: 60%;;
  }
}

#divein-signup {
  text-decoration: none;
  }

  #localAccountForm .divider  h1{
  display: none;
  }

  #orMessage{
    display: none;
  }
  
  /* social button  */
  #api .social button:hover{
     background-color: #dbedff;
  }

  .social img{
    width: 16px;
    height: 16px;
  }
  
  #localAccountForm
  {
    margin-top: 1rem;
    display: none;
  }
  
  #signupButton, #signinEmail{
    color: #000 !important;
    border: 1px solid #605e5c !important;
    background-color: var(--bg-body);
  }

  #existing-user{
    color: #004991 !important;
    background-color: #E1E5Ed;        
    border: 0!important;
  }

  #new-user{
    color: #515459 !important;
    background-color: #E1E5Ed;
    display: none;
    border: 0!important;
  }

.social-style {
  font-size: 16px;
  cursor: pointer;
  color: #000 !important;
  width: 100% !important;
  overflow: hidden;
  border: 1px solid #605e5c !important;
  background-color: var(--bg-body);
  -moz-border-radius: .5rem;
  -webkit-border-radius: .5rem;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
  transition: 0.3s ease;
  text-align: center;
  height: 45px;
  margin-bottom: 0;
  white-space: normal;
  margin-top: 15px;
  height: 40px;
}

.accountButton {
  @extend .social-style;
}

#api .social button {
  @extend .social-style;
}

#signupButton:hover, #signinEmail:hover{
  background-color: var(--button-hover-bg);;
}

.accountButton img{
  width: 16px;
  height: 16px;
}

%common-styles {
  color: var(--input-border) !important;
  background-color: #E1E5Ed;
  width: 215px !important;
  border: 0!important;
}

#FacebookExchange img,
#GoogleExchange img,
#signupButton img,
#signinEmail img {
  transition: transform 0.3s ease, margin-right 0.3s ease;
}

#FacebookExchange:hover img,
#GoogleExchange:hover img,
#signupButton:hover img,
#signinEmail:hover img {
  transform: scale(1.5);
  margin-right: 5px;
}

.shared-hover-styles {
  transition: 0.3 ease-in-out;
  &:hover {
    background-color: var(--hover-bg);
  }
}

#existing-user,
#new-user,
#noAccount,
#newAccount,
#back {
  @extend .shared-hover-styles;
}

#noAccount {
  @extend %common-styles;
  display: none;
}

#newAccount {
  @extend %common-styles;
  margin-bottom: 1.8rem;
  width: 88px!important;
  img{
    margin-right: 5px;
  }
}

#newAccount a{
  text-decoration: none!important;
  color: var(--input-border)
}

#back {
  @extend %common-styles;
  display: none;
  width: 88px !important;
  margin-bottom: 24px;
}

.terms-conditions{
  margin-top: 2.5rem;
  font-size: 1rem;
  color: var(--dark);
  a {
    color: var(--dark);
  }
}

/*Signup page styles*/
#next-step{
  &:hover{
    opacity: 0.8!important;
  }
} 
#continue{
  display: none;
  &:hover{
    opacity: 0.8!important;
  }
}
     
#givenName,  #surname{
  display: none;
}
.wrap-slide{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 12px;
  margin-top: 32px;
}
.slide{
  border-radius: 50px;
  width: 10px;
  height: 10px;
}
.active-slide{
  background-color: #449EF7;
}
.inactive-slide{
  background-color: #AAAFB7;
}

/************/
/* Azure B2C Form styles */

.form-content h2 {
  display: none;
}

.form-content p,
.form-content label,
.form-content input + a {
  display: none !important;
}

.password-label{
  position: relative;
  width: 100%;
}
.form-content label + a {
  color: var(--text-body);
  font-size: 1rem;
  opacity: 0.8;
  text-decoration: none;
  border: 1px solid var(--button-bg);
  color: var(--dark);
  width: 100%;
  margin-top: 142px;
  position: absolute;
  text-align: center;
  padding: 8px 16px;
  border-radius: .5rem;
  transition: 0.3s ease-in-out;
  &:hover{
    background-color: var(--button-hover-bg);
  }
}
.helpLink.tiny{
  display: none;
}

.form-content label + .itemLevel,
.form-content .pageLevel {
  font-size: 0.75rem;
  color: var(--gray-900);
}

.form-content .pageLevel[aria-hidden="false"],
.reset-password .form-content .verificationErrorText.error[aria-hidden="false"] {
  background: var(--danger-bg);
  border-radius: 0.25rem;
  display: block !important;
  color: var(--danger-color);
  font-weight: 500;
  margin-bottom: 2rem;
  padding: 1rem;
}

.form-content .pageLevel p {
  display: block !important;
  margin: 0;
}

.form-content label + a:hover {
  opacity: 1;
  text-decoration: none;
}

.form-content label + a:after {
  clear: both;
}

.form-content ul {
  list-style: none;
  padding: 0;
}

.form-content input {
  background-color: var(--bg-body);
  border: 1px solid var(--input-border);
  border-radius: 0.50rem;
  box-sizing: border-box;
  margin: 0.5rem 0 1.5rem;
  padding: 8px 12px;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
}

.form-content input:focus {
  border-color: var(--gray-900);
  outline: 0;
}

.password-toggle {
  display: flex;
  justify-content: flex-end;
  .password-toggle-icon { 
    margin: -85px 10px 0 0;
    cursor: pointer;
  }
}
.hide-toggle{
  display: none;
}

.form-content button {
  background-color: var(--button-bg);
  border-radius: 0.50rem;
  color: var(--dark);
  padding: 0.5rem 1.8rem;
  width: 100%;
  border: 0;
  margin-top: 1.25rem;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}

.form-content button + button {
  display: none;
}

.form-content form > div:last-child {
  display: none;
}

// .form-content li.TextBox:last-child {
//   height: 0;
//   opacity: 0;
//   visibility: hidden;
// }

.azureHiddenInput {
  height: 0;
  opacity: 0;
  visibility: hidden;
}

// Modal
//=
.simplemodal-overlay {
  opacity: .75 !important;
}

.verifying-modal.simplemodal-data {
  color: var(--white);
  font-weight: bold;
}

// Reset Password
//==

.reset-password .form-content {
  .verificationInfoText {
    display: none !important;
  }

  #attributeVerification > div.buttons button {
    width: 100%;

    &[disabled] {
      display: none;
    }
  }

  .buttons.verify button {
    width: 100%;
  }

  .buttons.verify .verifyInput {
    margin: 0;
  }

  #email_ver_but_verify {
    margin-top: 1rem;
  }

  #email_ver_but_resend {
    background: none;
    border: none;
    color: #333;
    font-size: 0.9rem;
    float: right;
    padding: 5px 0 0;
    width: initial;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: "";
      clear: both;
    }
  }

  #email_ver_but_edit {
    display: none !important;
  }
}

.bg-position-top-20 {
  background-position-y: 20% !important;
}

.blockquote-footer:before {
  content: '';
}

.border-primary {
  border-color: $primary;
}

.border-top-4 {
  border-width: 4px 0 0 !important;
}

.display-medium {
  font-size: 8rem;
}

.font-normal {
  font-style: normal !important;
}

.font-size-sm {
  font-size: $font-size-sm !important;
}

.font-size-xl{
  font-size: 3.158rem;
}

.pt-6{
  padding-top: 2.5rem;
}
.w-text{
  width: 300px;
  line-height: 42.7px;
  font-size: 21.33px;
}

.growup-icon {
  width: 17.5rem;
}

.object-cover {
  object-fit: cover;
}

.py-6 {
  padding-bottom: 6rem;
  padding-top: 6rem;
}

.resize-none {
  resize: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.rounded-xl {
  @include border-radius($border-radius-xl);
}

.rounded-top-3 {
  @include border-top-radius(1rem);
}

.sticky-nav {
  padding-top: 5rem;
}

.opacity-05 {
  opacity: .5;
}


@include media-breakpoint-up(md) {
  .display-md-large {
    font-size: 10rem;
  }

  .font-size-md-base {
    font-size: $font-size-base !important;
  }

  .growup-icon {
    width: 20rem;
  }

  .px-md-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .w-md-auto {
    width: auto !important;
  }

  .w-md-50 {
    width: 50% !important;
  }
}

@include media-breakpoint-up(lg) {
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }

  .border-top-lg-0 {
    border-top: 0 !important;
  }

  .display-lg-5 {
    font-size: 3rem;
    line-height: 1;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-text{
    width: 900px;
  }
}

@include media-breakpoint-up(xl) {
  .w-xl-auto {
    width: auto !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }
}

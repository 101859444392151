footer {
  color: rgba($white, .5);

  .border-footer {
    border-color: rgba($white, .5);
  }

  .logo {
    opacity: .5;
  }

	.logo-nav-link {
		color: hsla(0,0%,100%,.5);
		text-decoration: none;
	}

  .contact-information {
    svg path {
      fill: white;
      opacity: .5;
    }

    &:hover {
      a {
        color: rgba($white, 1);
      }

      svg path {
        opacity: 1;
      }
    }
  }

  // .contact-information:hover >* {
  //   color: $white;
  // }

  .nav-link {
    color: rgba($white, .5);

    svg path {
      fill: rgba($white, .5);
    }
	}

  .form-control {
    background-color: theme-color("dark");
    border-color: rgba($white, .5);
    color: $white;

    &::placeholder {
      color: rgba($white, .5);
    }

    &:focus {
      background-color: inherit;
      border-color: $white;
      color: $white;
    }
  }
}

.flickity-carousel {
  margin-bottom: 4rem;

  .carousel-cell {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: .625rem;
    width: 100%;
    transition: opacity 2s;
    opacity: 0;

    &.is-selected {
      opacity: 1;
    }
  }

  .carousel-cell-image {
    max-height: 100%;
    margin: 0 auto;
    max-width: 100%;
    opacity: 0;
    transition: opacity .4s;
    box-shadow: 0 2px 4px rgba(3, 27, 78, .06);
  }

  .carousel-cell-image.flickity-lazyloaded,
  .carousel-cell-image.flickity-lazyerror {
    opacity: 1;
  }

  .flickity-button {
    .flickity-button-icon {
      fill: $divein-base;
    }

    &:hover .flickity-button-icon {
      fill: $primary;
    }
  }

  .flickity-page-dots {
    left: 0;
    bottom: -4rem;

    .dot {
      background-color: transparent;
      border: 1px solid $primary;
      height: .937rem;
      opacity: 1;
      width: .937rem;

      &.is-selected {
        background-color: $primary;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .flickity-button {
      display: none;
    }
  }
}
